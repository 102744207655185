import React, { useState } from 'react';

class Hello extends React.Component {
    
  constructor(props) {
      super(props);
      this.state = {
          name: "World",
          refre: "refresh"
      };
  }

//     const [refre, setRefre] = useState();
//     const refresh = ()=>{
//         setRefre({});
//     }

//     var min = 5 ;
//     var max = 10 ;
//     var a = Math.floor( Math.random() * (max + 1 - min) ) + min ;
//     var b = Math.floor( Math.random() * (max + 1 - min) ) + min ;



  render() {
    return (
        <div>
            <div>
                <h1>Hello {this.state.name}!</h1>
                <input 
                    value={this.state.name} 
                    onChange={(e) => this.setState({
                        name: e.target.value
                    })} 
                />
                <input 
                  type="button"
                    value={this.state.refre} 
                    onChange={(e) => this.setState({
                        refre: e.target.value
                    })} 
                />



            </div>
        </div>
    )
}
}

export default Hello;





// // 動作確認済みクラスでステート
// function MyComponent() {

//     const [refre, setRefre] = useState();
//     const refresh = ()=>{
//         setRefre({});
//     }

//     var min = 5 ;
//     var max = 10 ;
//     var a = Math.floor( Math.random() * (max + 1 - min) ) + min ;
//     var b = Math.floor( Math.random() * (max + 1 - min) ) + min ;
  
//     return (
//       <div>
//         <button onClick={ refresh }>Refresh Component</button>
//         {a}{b}

//       </div>
//     );
//   }
  
//   export default MyComponent;